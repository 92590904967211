import { Type as RewardType } from '@wix/ambassador-loyalty-referral-v1-program/types';
import { autorun, when } from 'mobx';

import { createBiLogger } from '../../viewer';
import { ElementId } from './constants';
import model from './model';

export default model.createController(({ $bind, $widget, flowAPI, $props, $w }) => {
  const { t } = flowAPI.translations;
  const { wixCodeApi } = flowAPI.controllerConfig;
  const biLogger = createBiLogger(flowAPI.bi);

  return {
    async pageReady() {
      const rewardStates = $w(`#${ElementId.RewardStates}`);

      autorun(() => {
        if ($props.isLoading) {
          rewardStates.changeState(ElementId.LoadingState);
        } else if ($props.isLoggedIn && $props.rewardType === RewardType.COUPON && !$props.isError) {
          rewardStates.changeState(ElementId.CouponRewardState);
        } else {
          rewardStates.changeState(ElementId.GetRewardState);
        }
      });

      when(
        () => $props.isLoading,
        () => {
          wixCodeApi.animations
            .timeline({
              repeat: -1, // Repeat infinitely
            })
            .add($w(`#${ElementId.Loader}`), {
              duration: 1000,
              rotate: 360,
              rotateDirection: 'cw',
              easing: 'easeLinear',
            })
            .play();
        },
      );

      $bind(`#${ElementId.TitleText}`, {
        text: () => $props.title,
        collapsed: () => !$props.title,
      });

      $bind(`#${ElementId.SubtitleText}`, {
        text: () => $props.subtitle,
        collapsed: () => !$props.subtitle,
      });

      $bind(`#${ElementId.ExplanationsText}`, {
        text: () => $props.explanations,
        collapsed: () => !$props.explanations,
      });

      $bind(`#${ElementId.CopyCode}`, {
        text: () => $props.couponCode,
        onCopy() {
          biLogger.referredFriendAction('Copy code');
        },
        onInputClick() {
          biLogger.referredFriendAction('Coupon Text Input Click');
        },
      });

      $bind(`#${ElementId.GetRewardButton}`, {
        label: () => $props.rewardButtonLabel,
        onClick: () => $widget.fireEvent('rewardButtonClick', {}),
      });

      $bind(`#${ElementId.MyRewardsText}`, {
        html() {
          const text = t('referral-page.my-rewards-text').replace(
            /<1>(.*?)<\/1>/,
            $props.myRewardsPageUrl
              ? `<a href="${$props.myRewardsPageUrl}" target="_self" style="text-decoration: underline;">$1</a>`
              : '$1',
          );

          return `<p>${text}</p>`;
        },
      });
    },
    exports: {},
  };
});
